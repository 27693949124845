@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&family=Open+Sans&display=swap');

.cursive {
    font-family: 'Libre Baskerville', serif;
    font-weight: 700;
}

.prose {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.home-name {
    font-size: 150px;
}