@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.cursive {
    font-family: 'Libre Baskerville', serif;
    font-weight: 700;
}

.prose {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.home-name {
    font-size: 150px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
}

.Resume{
    background: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 100vh;
    transition: background 0.1s;
}
.Resume.dark {
    background: black;
}
.Resume.dark .right_side {
    background: #222222;
}
.Resume.dark .title2 {
    color: #fff;
}
.Resume.dark p {
    color: #fff;
}

.resume-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    min-height: 1000px;
    /* background: #fff; */
    margin: 50px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    box-shadow: 0 55px 55px rgba(0, 0, 0, 0.2);
}

.Resume.dark .resume-container{
    box-shadow: 0 55px 55px rgba(255, 255, 255, 0.05);
}


.resume-container .left_side {
    position: relative;
    background: #222222;
    padding: 40px;
}

.profileText {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.profileText .imgBx {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
}

.profileText .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.profileText h2 {
    overflow: hidden;
    border-right: 0.15em solid #ef233c;
    white-space: nowrap;
    color: white;
    font-size: 1.5rem;
    margin-top: 20px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 1.4em;
    -webkit-animation: typing 3.5s steps(50, end),
    blink-caret .5s step-end infinite;
            animation: typing 3.5s steps(50, end),
    blink-caret .5s step-end infinite;
}

@-webkit-keyframes typing {
    from {
        width: 0
    }
    to {
        width: 80%;
    }
}

@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 80%;
    }
}

@-webkit-keyframes blink-caret {
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: #ef233c
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: #ef233c
    }
}

.profileText span {
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
}

.profileText h2 span {
    font-size: 0.8em;
    font-weight: 300;
}

.contactInfo {
    margin-top: 40px;
}

.contactInfo .title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.contactInfo ul {
    position: relative;
}

.contactInfo ul li {
    position: relative;
    list-style: none;
    margin: 10px 0;
    cursor: pointer;
}

.contactInfo ul li .icon {
    display: inline-block;
    width: 30px;
    font-size: 18px;
    color: #ef233c;
}

.fa-java {
    font-size: 22px !important;
}

.contactInfo ul li span {
    color: #fff;
    font-weight: 300;
}

.percent {
    position: relative;
    width: 100%;
    height: 6px;
    background: #222;
    display: block;
    margin-top: 5px;
}

.percent div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #ef233c;
}

.resume-container .right_side {
    position: relative;
    background: #ffffff;
    padding: 40px;
    transition: background 0.1s;
}

.about {
    margin-bottom: 50px;
}

.about:last-child {
    margin-bottom: 0;
}

.title2 {
    color: #222222;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
    transition: color 0.2s;
    font-weight: 600;
    font-size: large;
}

p {
    color: #222;
    font-size: 16px;
    transition: color 0.2s;
}

.wrapper .button {
    display: inline-block;
    height: 50px;
    width: 50px;
    /* float: left; */
    margin: 0 5px;
    overflow: hidden;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;
}

.Resume.dark .wrapper .button {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

.wrapper .button:hover {
    width: 200px;
}

.wrapper .button .icon {
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    border-radius: 50px;
    box-sizing: border-box;
    line-height: 50px;
    transition: all 0.3s ease-out;
    color: #222222;
}

.wrapper .button:nth-child(1):hover .icon {
    background: #4267B2;
}

.wrapper .button:nth-child(2):hover .icon {
    background: #1DA1F2;
}

.wrapper .button:nth-child(3):hover .icon {
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}


.wrapper .button:nth-child(4):hover .icon {
    background: #ff0000;
}

.wrapper .button .icon i {
    font-size: 25px;
    line-height: 50px;
    transition: all 0.3s ease-out;
}

.wrapper .button:hover .icon i {
    color: #fff;
}

.wrapper .button span {
    font-size: 20px;
    font-weight: 500;
    line-height: 50px;
    margin-left: 10px;
    transition: all 0.3s ease-out;
}

.wrapper .button:nth-child(1) span {
    color: #4267B2;
}

.wrapper .button:nth-child(2) span {
    color: #1DA1F2;
}

.wrapper .button:nth-child(3) span {
    color: #E1306C;
}

.wrapper .button:nth-child(4) span {
    color: #ff0000;
}

.toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    /*background: #ffffff;*/
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1)

}

.Resume.dark .toggle {
    background: #222222;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5)
}

.toggle:before {
    content: "\f186";
    font-family: "Font Awesome 5 Free", serif;
    color: #222222;
}

.right_side.dark .toggle:before {
    content: "\f185";
    font-family: "Font Awesome 5 Free", serif;
    color: #ffffff;
}

.about .box {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: 20px 0;
}

.about .box .year_company {
    min-width: 150px;
}

.about .box .year_company h5 {
    text-transform: uppercase;
    color: rgba(43, 45, 66, 0.71);
    font-weight: 600;
    transition: color 0.2s;
}

.Resume.dark .about .box .year_company h5 {
    color: #fff;
}

.about .box .text h4 {
    text-transform: uppercase;
    color: #ef233c;
    font-size: 16px;
}

.skills .box {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1fr;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center
}

.skills .box h4 {
    text-transform: uppercase;
    color: rgba(43, 45, 66, 0.71);
    font-weight: 500;
    transition: color 0.2s
}

.Resume.dark .skills .box h4 {
    color: #fff;
}

.skills .box .percent {
    position: relative;
    width: 100%;
    height: 10px;
    background: #f0f0f0
}

.credits {
    position: absolute;
    right: 20px;
    bottom: 5px;
    /*margin-top:400px;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    text-align: right;
}

.credits p {
    text-transform: uppercase;
}

.credits i {
    font-size: 20px;
    margin: 0 5px;
    cursor: pointer;
}

.Resume.dark .credits i{
    color: #ffffff;
}

@media (max-width: 1000px) {
    .resume-container {
        margin: 10px;
        grid-template-columns:repeat(1, 1fr);
    }
}

@media (max-width: 600px) {
    .about .box {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .about .box .year_company {
        margin-bottom: 5px;
    }

    .skills .box {
        grid-template-columns:repeat(1, 1fr);
    }

    .wrapper {
        display: grid;
        grid-template-columns:repeat(1, 1fr);
    }

    .wrapper .button {
        margin: 10px 0;
    }
}

